import React from 'react';
import organizationImage from '../assets/images/lecturemeet-image.jpg';
import staffImage from '../assets/images/org.jpg';
import { Link } from 'react-router-dom';

const OrganizationSection = () => {
  return (
    <section className="organization-section">
      <div className="organization-left">
        <div className="org-left-image-container">
          <img src={organizationImage} alt="Organization" />
        </div>
        <div className="organization-data">
          <div className="data-item">
            <h4>Teams</h4>
            <p>100+</p>
          </div>
          <hr />
          <div className="data-item">
            <h4>Organizations</h4>
            <p>500+</p>
          </div>
          <hr />
          <div className="data-item">
            <h4>Staff Trained</h4>
            <p>10,000+</p>
          </div>
        </div>
      </div>
      <div className="organization-right">
        <div className="org-right-image-container">
          <img src={staffImage} alt="Staff" />
        </div>
        <div className="organization-text">
          <h2>Grow with the team</h2>
          <p>
          Designed to empower businesses and institutions by providing tailored learning solutions that drive innovation, enhance team performance, and foster growth
          </p>
          <Link to="https://lecturemeet.com/Organisations" className="btn btn-primary" style={{ textDecoration: 'none' }}>
  Onboard Team
</Link>
        </div>
      </div>
    </section>
  );
};

export default OrganizationSection;
