import React from 'react';
import { Helmet } from 'react-helmet';
import banking from '../assets/images/banking.avif';
import Corporateplan from '../usercomponents/pricingplans/Corporateplan';
import Meta from '../components/Meta';






const CorporateSector = () => {
  const location = 'Nigeria';
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Corporate & Banking Finance Department | LectureMeet</title>
        <Meta name="description" content="Enhance your skills with our specialized course in corporate finance, banking, and business management. Advance your career today." />
<Meta name="keywords" content="Lecturemeet, corporate finance, banking, business management, professional learning, career advancement, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/corporate-and-banking-sector",
              "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
      "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>
        
        <Meta property="og:title" content="Corporate & Banking Finance Department | LectureMeet" />
        <Meta property="og:description" content="Enhance your skills with our specialized course in corporate finance, banking, and business management. Advance your career today." />
        <Meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
        <Meta property="og:url" content="https://lecturemeet.com/corporate-and-banking-sector" />
        <Meta name="twitter:card" content="summary_large_image" />

        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
        
      </Helmet>


      <section className="Corporate-09Sec">
  <div className="corp-sect-container">
    <div className="corp-colum">
      <h1 className="text-3xl font-bold mb-4">About Corporate and Finance</h1>
      <p className="mb-4" style={{ textAlign: 'left', color: 'black', fontSize: '20px', }}>
      The Corporate and Finance program offered by Lecturemeet is 
      designed to provide learners with a comprehensive understanding 
      of key concepts and practices in corporate governance, 
      risk management, anti-money laundering (AML/CFT), business marketing, 
      and strategy. 
      </p>
      <p className="mb-4" style={{ textAlign: 'left', color: 'black', fontSize: '20px',}}>
      This program is aimed at both individual professionals 
      seeking to enhance their skills and knowledge in these areas, as well 
      as organizations looking to upskill;
      </p>
      <ul style={{ listStyleType: 'none', padding: '0', margin: '0', fontSize: '20px', }}>
  <li style={{ marginBottom: '10px', textAlign: 'left', color: 'black', fontWeight: 'bold' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-dotted" viewBox="0 0 16 16">
  <path d="M2.5 0q-.25 0-.487.048l.194.98A1.5 1.5 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458q.151 0 .293.029l.194-.981A2.5 2.5 0 0 0 13.5 0m2.079 1.11a2.5 2.5 0 0 0-.69-.689l-.556.831q.248.167.415.415l.83-.556zM1.11.421a2.5 2.5 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415zM16 2.5q0-.25-.048-.487l-.98.194q.027.141.028.293v.458h1zM.048 2.013A2.5 2.5 0 0 0 0 2.5v.458h1V2.5q0-.151.029-.293zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458q0 .25.048.487l.98-.194A1.5 1.5 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458q0 .151-.029.293l.981.194Q16 13.75 16 13.5M.421 14.89c.183.272.417.506.69.689l.556-.831a1.5 1.5 0 0 1-.415-.415zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373Q2.25 16 2.5 16h.458v-1H2.5q-.151 0-.293-.029zM13.5 16q.25 0 .487-.048l-.194-.98A1.5 1.5 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
</svg> Enhanced team performance
  </li>
  <li style={{ marginBottom: '10px', textAlign: 'left', color: 'black', fontWeight: 'bold' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-dotted" viewBox="0 0 16 16">
  <path d="M2.5 0q-.25 0-.487.048l.194.98A1.5 1.5 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458q.151 0 .293.029l.194-.981A2.5 2.5 0 0 0 13.5 0m2.079 1.11a2.5 2.5 0 0 0-.69-.689l-.556.831q.248.167.415.415l.83-.556zM1.11.421a2.5 2.5 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415zM16 2.5q0-.25-.048-.487l-.98.194q.027.141.028.293v.458h1zM.048 2.013A2.5 2.5 0 0 0 0 2.5v.458h1V2.5q0-.151.029-.293zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458q0 .25.048.487l.98-.194A1.5 1.5 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458q0 .151-.029.293l.981.194Q16 13.75 16 13.5M.421 14.89c.183.272.417.506.69.689l.556-.831a1.5 1.5 0 0 1-.415-.415zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373Q2.25 16 2.5 16h.458v-1H2.5q-.151 0-.293-.029zM13.5 16q.25 0 .487-.048l-.194-.98A1.5 1.5 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
</svg> Career advancement 
  </li>
  <li style={{ marginBottom: '10px', textAlign: 'left', color: 'black', fontWeight: 'bold' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-dotted" viewBox="0 0 16 16">
  <path d="M2.5 0q-.25 0-.487.048l.194.98A1.5 1.5 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458q.151 0 .293.029l.194-.981A2.5 2.5 0 0 0 13.5 0m2.079 1.11a2.5 2.5 0 0 0-.69-.689l-.556.831q.248.167.415.415l.83-.556zM1.11.421a2.5 2.5 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415zM16 2.5q0-.25-.048-.487l-.98.194q.027.141.028.293v.458h1zM.048 2.013A2.5 2.5 0 0 0 0 2.5v.458h1V2.5q0-.151.029-.293zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458q0 .25.048.487l.98-.194A1.5 1.5 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458q0 .151-.029.293l.981.194Q16 13.75 16 13.5M.421 14.89c.183.272.417.506.69.689l.556-.831a1.5 1.5 0 0 1-.415-.415zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373Q2.25 16 2.5 16h.458v-1H2.5q-.151 0-.293-.029zM13.5 16q.25 0 .487-.048l-.194-.98A1.5 1.5 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
</svg> Risk mitigation and competitive edge
  </li>
  <li style={{ marginBottom: '10px', textAlign: 'left', color: 'black', fontWeight: 'bold' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-dotted" viewBox="0 0 16 16">
  <path d="M2.5 0q-.25 0-.487.048l.194.98A1.5 1.5 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458q.151 0 .293.029l.194-.981A2.5 2.5 0 0 0 13.5 0m2.079 1.11a2.5 2.5 0 0 0-.69-.689l-.556.831q.248.167.415.415l.83-.556zM1.11.421a2.5 2.5 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415zM16 2.5q0-.25-.048-.487l-.98.194q.027.141.028.293v.458h1zM.048 2.013A2.5 2.5 0 0 0 0 2.5v.458h1V2.5q0-.151.029-.293zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458q0 .25.048.487l.98-.194A1.5 1.5 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458q0 .151-.029.293l.981.194Q16 13.75 16 13.5M.421 14.89c.183.272.417.506.69.689l.556-.831a1.5 1.5 0 0 1-.415-.415zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373Q2.25 16 2.5 16h.458v-1H2.5q-.151 0-.293-.029zM13.5 16q.25 0 .487-.048l-.194-.98A1.5 1.5 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
</svg> Professional certification
  </li>
</ul>

    </div>
    <div className="corp-colum">
      <img src={banking} alt="Corporate course" className="img-fluid" />
    </div>
  </div>
</section>


<section className="columns-container-corporate" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
  <h2 style={{ textAlign: 'left', marginBottom: '20px', fontSize: '36px', fontWeight: 'bold' }}>Corporate and Finance Programme</h2>
  <p style={{ marginBottom: '20px', fontSize: '20px' }}>
  This course covers the principles and practices of corporate governance,<br /> 
  including board structures and memberships, regulatory requirements, and corporate best practices. <br />
  Identifying, assessing, and managing risks in the corporate and banking <br />
  sectors. Also learn about the principles and practices of AML/CFT <br />
  compliance in the banking and financial sectors, understanding of key <br />
  strategic concepts and frameworks in business strategy.
  </p>
  
  <h4 style={{ textAlign: 'left', marginBottom: '20px', fontSize: '36px', fontWeight: 'bold' }}>Learning module</h4>
  <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Corporate Governance and due diligence</li>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Risk Management</li>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Anti-Money Laundering (AML/CFT)</li>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Business Marketing and Strategy</li>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Business valuation, mergers and acquisitions</li>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Strategic management in business</li>
    <li style={{ marginBottom: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg> Corporate restructuring</li>
  </ul>
</section>



<section className="columns-container-corporate" style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
  <h4 style={{ textAlign: 'left', marginBottom: '20px', fontSize: '36px', fontWeight: 'bold' }}>Learning expectations</h4>
  <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
    <li style={{ marginBottom: '10px' }}>Introduction to Corporate Governance</li>
    <li style={{ marginBottom: '10px' }}>Regulatory Requirements and Compliance</li>
    <li style={{ marginBottom: '10px' }}>Best practices in corporate governance</li>
    <li style={{ marginBottom: '10px' }}>Board structures and responsibilities</li>
    <li style={{ marginBottom: '10px' }}>Shareholding and minority stake</li>
    <li style={{ marginBottom: '10px' }}>Business valuation, mergers and acquisitions</li>
    <li style={{ marginBottom: '10px' }}>Strategic management in business</li>
    <li style={{ marginBottom: '10px' }}>Corporate restructuring</li>

    <li style={{ marginBottom: '10px' }}>Marketing fundamentals</li>
    <li style={{ marginBottom: '10px' }}>Market analysis and segmentation</li>
    <li style={{ marginBottom: '10px' }}>Marketing strategies and tactics</li>
    <li style={{ marginBottom: '10px' }}>Branding and positioning</li>
    <li style={{ marginBottom: '10px' }}>Marketing campaign development</li>

    <li style={{ marginBottom: '10px' }}>Introduction to Risk Management</li>
    <li style={{ marginBottom: '10px' }}>Risk Identification and Assessment</li>
    <li style={{ marginBottom: '10px' }}>Risk Mitigation Strategies</li>
    <li style={{ marginBottom: '10px' }}>Risk Monitoring and Reporting</li>


    <li style={{ marginBottom: '10px' }}>Introduction to AML/CFT and regulatory frameworks</li>
    <li style={{ marginBottom: '10px' }}>Customer Due Diligence (CDD) and Know Your Customer (KYC) Requirements</li>
    <li style={{ marginBottom: '10px' }}>AML/CFT monitoring and reporting</li>
 

  </ul>
</section>

<br />
<br />
<Corporateplan location={location}/>

    </div>
  );
};

export default CorporateSector;
