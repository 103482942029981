import React from 'react';
import { Link } from 'react-router-dom';
import study from '../assets/images/study.jpg';


const ProgramsSection = () => (
  <section className="prograamessection">
    <div className="container">
      <div className="row">
        <div className="col-md-6 progsect-left">
        <div className="row">
            <div className="col-sm-6 progra-column">
              <div className="progra-card">
                <h3>Due Process and Corporate Governance</h3>
                <p>Learn principles and practices of corporate governance, including board structures, shareholder rights, and ethical considerations</p>
              </div>
            </div>
            <div className="col-sm-6 progra-column">
              <div className="progra-card">
                <h3>Resource & Products Development</h3>
                <p>Effective resource management is crucial for organizations to achieve goals efficiently and sustainably.</p>
              </div>
            </div>
          </div>
          <h2>Staying in the market</h2>
          <p> Gain the skills needed to drive product success in the market, from conceptualization to launch, and understand how to analyze markets and manage product lifecycles effectively.</p>
          <Link to="https://lecturemeet.com/Programme" className="btn btn-primary" style={{ textDecoration: 'none' }}>
  Programmes & Courses
</Link>
        </div>
        <div className="col-md-6 progsect-right">
        <img src={study} alt="Program" className="img-fluid" style={{ borderRadius: '10px' }} />
</div>
      </div>
    </div>
  </section>
);

export default ProgramsSection;
