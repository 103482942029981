import React from 'react';
import '../assets/css/AdmissionRequirements.css';
import { Helmet } from 'react-helmet';
import Meta from '../components/Meta';


const AdmissionRequirements = () => {
    return (
      <div className="admission-requirements">
          <Helmet>
        <title>Admissions | Lecturemeet </title>
        <Meta name="description" content="Enroll in our Pre-Degree Programme and professional courses. Start your journey towards career advancement and personal growth today." />
<Meta name="keywords" content="Lecturemeet, admission, pre-degree programme, professional courses, enrollment, career advancement, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/admission-for-pre-degree",
              "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
      "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>
        
        <Meta property="og:title" content="Admissions - Lectuemeet" />
        <Meta property="og:description" content="Enroll in our Pre-Degree Programme and professional courses. Start your journey towards career advancement and personal growth today" />
        <Meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
        <Meta property="og:url" content="https://lecturemeet.com/admission-for-pre-degree" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />

        
      </Helmet>
        <header className="header">
          <h1>Lecturemeet Pre-Degree Programme<br />
          Admission Requirements</h1>
        </header>
        <section className="section intro">
          <p>Lecturemeet is inviting applications from qualified candidates 
            for admission into its 3 months foundational crash Pre-Degree 
            programme. <br />
             <br />
            It offers students the opportunity to gain a solid 
            foundation in their chosen fields of study. The available programmes on Lecturemeet are Law, Mass Communication, and Business 
            Administration.</p>
        </section>
        <section className="section about">
          <h2>About the Pre-Degree Programmes</h2>
          <p>The Pre-Degree Programme at Lecturemeet is designed to prepare candidates for admission into 100 Level degree programmes within any University.</p>
          <br />
          <p>The Pre-Degree Programmes at Lecturemeet are designed for 
            students who have completed their secondary education 
            and are looking to gain foundational knowledge in their chosen 
            fields before advancing to higher education. 
            <br />
            <br />These programmes provide an intensive and comprehensive 
            introduction to key areas of study, equipping students with 
            the skills and knowledge necessary for academic and professional 
            success.</p>
        </section>
        <section className="section entry-requirements">
          <h2>Entry Requirements (Specific to Nigeria curriculum)</h2>
          <ul>
            <li>Must be a Nigerian or studied in any African country</li>
            <li>Must have completed secondary education (SSCE/WAEC/NECO) with a minimum of five credits, including English Language and Mathematics.</li>
            <li>Must be at least 15 years old at the time of application.</li>
            <li>Submission of a completed application form along with relevant academic results or certificates.</li>
            <li>Pass the entrance assessment by Lecturemeet.</li>
          </ul>
        </section>
        <section className="section duration">
          <h2>Programme Duration</h2>
          <p>Each Pre-Degree Programme lasts for 3 months, providing a focused and intensive learning experience.</p>
        </section>
        <section className="section payment-mode">
          <h2>Payment Mode</h2>
          <ul>
            <li>Full Payment: A one-time payment for the entire programme.</li>
            <li>Instalment Payment: Payments can be made in two instalments over the duration of the programme.</li>
          </ul>
        </section>
        <section className="section programme-descriptions">
          <h2>Programme Descriptions</h2>
          <hr />
          <h3>Mass Communications</h3>
          <p>This programme introduces students to the dynamic world of mass media, covering areas such as journalism, broadcasting, public relations, and advertising. Students will gain an understanding of the role of media in society, learn the basics of news writing and reporting, and explore the principles of effective communication and media ethics.</p>
          
          <h3>Foundation in Law</h3>
          <p>The Foundation in Law programme is ideal for students considering a career in law. This course provides a strong foundational background in law for further studies, covering various aspects of law in the first two years of higher education, including legal methods, contract law, criminal law, and family law. Students will develop critical thinking skills and an understanding of legal principles and their application.</p>
         
          <h3>Business Administration</h3>
          <p>The Business Administration programme is designed to equip students with essential knowledge and skills in business management. Students learn about various aspects of business, including management principles, marketing strategies, and financial management. The course prepares students for further studies in business and management, providing a solid foundation for a successful career in the business world.</p>
        
        
          <h3>History and International Relations</h3>
    <p>The History and International Relations programme provides students with a comprehensive understanding of historical events and their impact on contemporary global issues. This course covers significant historical milestones, theories of international relations, and the roles of various global entities. Students will develop analytical skills and the ability to critically assess historical contexts and their relevance to current affairs.</p>
</section>


        <section className="section additional-details">
          <h2>Additional Details</h2>
          <ul>
            <li>Mode of Study: The programmes are delivered through a combination of online lectures, assessments and examinations ensuring a comprehensive learning experience. Attendanc rate for a student to undertake the exam and earn certificate is 75%. Courses are based on units similar to university approacha and standard.</li>
            <li>Certification: Upon successful completion of the programme, students will receive a certificate from JoeNest (Lecturemeet), recognizing their achievement and readiness for further studies or professional opportunities.</li>
            <li>Support Services: Lecturemeet provides academic support, including tutoring and mentoring, to help students succeed in their studies. Career counselling services are also available to guide students in making informed decisions about their future careers.</li>
            <li>Career: The certificate earned through the Pre-Degree Program can help students secure internships in their chosen fields, providing practical experience and exposure to industry practices.</li>
          </ul>
        </section>



        <section className="section program-disclaimer">
    <h2>Important Disclaimer</h2>
    <p>
        The Pre-Degree program and the certificate issued upon completion are not university degrees or their equivalents. At Lecturemeet, our mission is to transform professional learning through flexible and accessible educational solutions. We recognize the challenges faced by learners in accessing quality education, which is why we focus on offering a diverse range of certification courses across various fields, including compliance, technology, and business.
    </p>
    <p>
        As Lecturemeet evolved, we became increasingly aware of the need for foundational education that prepares students for higher learning. This insight led to the development of JoeNest, a new platform aimed at providing a foundational crash course program based on a university curriculum model. JoeNest is designed to equip students with the essential skills and knowledge necessary for success in higher education.
    </p>
    <p>
        With a mission to improve learning beyond borders, JoeNest aspires to obtain the necessary licenses to expand into a fully-fledged open learning university. This ambitious goal reflects our ongoing commitment to making quality education accessible to all, regardless of geographical limitations. The journey from Lecturemeet to JoeNest represents a significant step towards creating a more inclusive and supportive educational landscape for learners worldwide.
    </p>
</section>

      </div>
    );
  };
  
  export default AdmissionRequirements;