import React, { useState } from 'react';
import '../Pre-degree/css/Notification.css';

function NotificationAlert() {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="NotificationAlert">
      {showPopup && (
        <div className="NotificationAlert-popup">
          <div className="NotificationAlert-popup-content">
            <span className="NotificationAlert-close-btn" onClick={handleClosePopup}>&times;</span>
            <h2>Disclaimer Notice:</h2>
            <p>
              The Pre-Degree/Diploma Program offered by <strong>JoeNest</strong> (Lecturemeet) is not a degree course, and no degree certificate will be awarded upon completion. This program is a foundational crash course designed to integrate regular university learning modules and curricula, providing essential knowledge for further academic pursuits.
              While the certificate issued upon completion may be used to gain entry into partner universities or where we so get approved license to operate as a tertiary institution, you may seek internship placements and explore work opportunities with the certificate. The certificate does not equate to a university degree.
            </p>
            <p className="text-left">Signed</p>
            <p>
              Team <br /> JoeNest (Lecturemeet)
            </p>
            <p>Thank you.</p>

            {/* Accept Button */}
            <button className="NotificationAlert-accept-btn" onClick={handleClosePopup}>
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationAlert;
