import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import campushall from '../assets/images/campushall.avif';
import './css/Notification.css';
import '../assets/css/Pre-degree.css';
import LearningModelBusinessAdmin from './Module/LearningModel-BusinessAdmin';
import { Link } from 'react-router-dom';
import Buildlearn from '../assets/images/online.jpg';
import Notification from '../components/Notification';




const MassComm = ({ imageUrl, header, text }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Admission-for-pre-degree'); 
  };


    const handleClickPrograms = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Business Administration - Pre-Degree Programme | Lecturemeet </title>
        <meta name="description" content="Build a future today by enrolling in Lecturemeet's Business Administration pre-degree foundational crash course using university curriculum" />
<meta name="keywords" content="Lecturemeet, Business Administration, Pre-Degree Programme, academic preparation, professional development, education, business studies" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Pre-degree/Business-Administration",
        "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
      "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>

<meta property="og:title" content="Business Administration - Pre-Degree Programme | Lecturemeet" />
<meta property="og:description" content="Build a future today by enrolling in Lecturemeet's Business Administration pre-degree foundational crash course using university curriculum" />
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
<meta property="og:url" content="https://lecturemeet.com/Pre-degree/Business-Administration" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />

      </Helmet>




      <div className="pre-degree-program-course-hero">
            <h1 className="pre-degree-program-course-header">Pre-Degeee Course Application 
            Commences <br />in November, 2024</h1>
            <p className="pre-degree-program-course-paragraph">
                Get ready to embark on an exciting learning journey with LectureMeet! Our pre-degree programs are designed 
                to give you the foundational knowledge and skills you need to excel in your chosen field. Join us and 
                experience the joy of learning like never before.
            </p>
            <a href="https://pre-degree.lecturemeet.com/onboard" className="pre-degree-program-course-button">
                Notify Me
            </a>
        </div>


        

      <section className="prog-mass-comm">
  <div className="container">
    <div className="header-content">
      <h1 className='text-white'>Business Administration</h1>
      <p className='text-white'>Join other learners to embark on a learning journey.</p>
      <button onClick={handleClick}>See Requirements</button>
    </div>
  </div>
</section>



<br />

    <section className="scroll-to-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <a href="#about-program" className="scroll-to-link" onClick={() => handleClickPrograms('about-program')}>About Program</a>
          </div>
          <div className="col-md-3">
            <a href="#admission" className="scroll-to-link" onClick={() => handleClickPrograms('admission')}>Admission & Requirements</a>
          </div>
          <div className="col-md-3">
            <a href="#fees" className="scroll-to-link" onClick={() => handleClickPrograms('fees')}>Fees</a>
          </div>
          <div className="col-md-3">
            <a href="#learning-model" className="scroll-to-link" onClick={() => handleClickPrograms('learning-model')}>Learning Model</a>
          </div>
          <div className="col-md-3">
            <a href="#scholarship" className="scroll-to-link" onClick={() => handleClickPrograms('scholarship')}>Scholarship</a>
          </div>
          <div className="col-md-3">
            <a href="#internship" className="scroll-to-link" onClick={() => handleClickPrograms('internship')}>Internship Opportunities</a>
          </div>
          <div className="col-md-3">
            <a href="#remote-job" className="scroll-to-link" onClick={() => handleClickPrograms('remote-jobs')}>Career </a>
          </div>
          <div className="col-md-3">
            <a href="#benefits" className="scroll-to-link" onClick={() => handleClickPrograms('benefits')}>Benefits</a>
          </div>
        </div>
      </div>
    </section>



    <section className="prog-190" id='about-program'>
    <div className="container">
      <div className="row">        
      <div className="col-md-6 pmsect-09">
          <img src={campushall} alt="About the Business Administration Programmes" className="img-fluid" />
            </div>
        <div className="col-md-6 pmsect-09">
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: 'left', color: 'blue' }}>Diploma in Business Administration</h1>
          <p>The Business Administration Pre-Degree or Diploma 
            Program offers a foundational learning 
            module to essential business concepts and practices. 
            Using the tertiary curriculum standard, it equips students 
            with fundamental knowledge in business management, 
            organizational behavior, and financial principles, 
            providing a solid foundation for higher education 
            in business or immediate entry into the business sector </p>

            <p>
            The Business Administration Pre-Degree program aims to 
            equip students with a solid understanding of fundamental 
            business concepts, management principles, and practical skills 
            required to excel in the business environment. It provides a broad 
            overview of business operations and is designed to prepare students 
            for more specialized business studies at the undergraduate level or 
            for entry into various business roles.
            </p>
         
        </div>

      </div>
    </div>
  </section>







 {/* Admission & Requirements Section */}
 <section className="admission-requirements container-text" id="admission">
                <div className="container">
                    <h2>Admission & Requirements</h2>
                    <p>Find out the entry requirements and application process for the Business Administration Pre-degree program.</p>
                    <ul>
                        <li>High School Diploma or equivalent</li>
                        <li>Must have completed secondary education (SSCE/WAEC/NECO) with a minimum of five credits, including English Language and Mathematics.</li>
                        <li>Must be a Nigerian or studied in any African country</li>
                        <li>Must be at least 16 years old at the time of application</li>
                        <li>Pass the entrance assessment by Lecturemeet</li>
                    </ul>
                </div>
            </section>

            {/* Fees Section */}
            <section className="fees container-text" id="fees">
                <div className="container">
                    <h2>Program Fees</h2>
                    <br />
                    <p>Duration: 3 months</p>
                   <ul>
                        <li>Non-Refundable Application Fee: ₦15,000</li>
                        <li>Tuition Fee: ₦185,000 <s className='text-danger'>₦245,000</s> (With learning kits and physical certificate delivery)</li>
                        <li>Tuition Fee: 85,000 <s className='text-danger'>₦130,000</s> (Without learning kits). Materials are all digital </li>
                        <br />
                        <p>Any of the fees covers academic study for the duration inclusive of the course materials</p>
                       </ul>
                   
                </div>
            </section>

        

            {/* Learning Model Section */}
            <section className="learning-model" id="learning-model">
<LearningModelBusinessAdmin />
            </section>


            {/* Scholarship Section */}
            <section className="scholarship container-text" id="scholarship">
            <div className="container">
                <h2>Scholarship Opportunities</h2>
                <p>Offering scholarships for our Business Administration Pre-degree program, covers tuition and learning materials for a 3-month duration with a diploma upon completion. Scholarships are currently open for only eligible applicants and are awarded based on merit and financial need.</p>
                <ul>
                    <li>Merit-based Scholarships</li>
                    <li>Need-based Financial Aid</li>
                    <li>Special Grants from support partners</li>
                </ul>
                <Link 
                    to="/Scholarship" 
                    className="btn todayforYou" 
                    style={{
                        display: 'inline-block',
                        padding: '0.5rem 1rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#ffffff',
                        backgroundColor: '#007bff',
                        border: 'none',
                        borderRadius: '0.25rem',
                        textDecoration: 'none',
                        textAlign: 'left',
                        marginTop: '1rem',
                        transition: 'background-color 0.3s'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                >
                    Explore Scholarship
                </Link>
            </div>
        </section>

            {/* Internship Opportunities Section */}
            <section className="internship container-text" id="internship">
                <div className="container">
                    <h2>Internship Opportunities</h2>
                    <p>Learn about internship placements and career support provided during and after the program.</p>
                    <ul>
                        <li>Industry Partnerships</li>
                        <li>Work Experience Opportunities</li>
                        <li>Career Counseling</li>
                    </ul>
                </div>
            </section>

            {/* Job Apply Section */}
            <section className="paypercourse-section" id="remote-job">
  <div className="paypercourse-container">
    <div className="per-course-left">
      <h2>Explore Career Options in Business Administration</h2>
      <p>Where can you be
        <br />
        Graduates of the Business Administration Pre-Degree program can pursue various career paths, including:
        </p>

<div className="per-course-grid">
        <div className="per-course-item">Business Manager</div>
        <div className="per-course-item">Marketing and Sales</div>
        <div className="per-course-item">Financial analysis</div>
        <div className="per-course-item">Administrative Support</div>
        <div className="per-course-item">Busines planning</div>
      </div>
    </div>
    <div className="per-course-right">
      <img src={Buildlearn} alt="Career options in Mass Communications" />
    </div>
  </div>
</section>


            {/* Benefits Section */}
            <section className="benefits container-text" id="benefits">
                <div className="container">
                    <h2>Program Benefits</h2>
                    <p>Discover the benefits of enrolling in the Mass Communications pre-degree program.</p>
                    <ul>
                        <li>Internship Opportunity</li>
                        <li>Partner direct entry</li>
                        <li>Networking Opportunities</li>
                        <li>Career Advancement</li>
                    </ul>
                </div>
            </section>


<Notification />




    </div>
  );
};

export default MassComm;
