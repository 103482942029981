import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Meta from '../components/Meta';




const LoginLM = () => {
  return (
    <div className="Login-LM-container">
      <Helmet>
        <title>Get Started Using Lectuemeet | Learning Beyond Borders | Professional | Certification</title>
        <Meta name="description" content="Get started with Lecturemeet by logging in or creating an account and access a wide range of professional courses and enhance your career today." />
<Meta name="keywords" content="Lecturemeet, login, create account, get started, professional courses, career enhancement, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />



        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/login",
              "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
              "description": "Get started with Lecturemeet by logging in or creating an account and access a wide range of professional courses and enhance your career today.",
      "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>
        
        <Meta property="og:title" content="Get Started Using Lectuemeet | Learning Beyond Borders | Professional | Certification" />
        <Meta property="og:description" content="Get started with Lecturemeet by logging in or creating an account and access a wide range of professional courses and enhance your career today." />
        <Meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
        <Meta property="og:url" content="https://lecturemeet.com/login" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
        
      </Helmet>

      <header className="Login-LM-header">
        <h1>Get started with Lecturemeet</h1>
        <p>Choose your learning program to get started 
            or continue from where you stopped</p>
      </header>
      <div className="Login-LM-sections">
        <Link to="https://organisation.lecturemeet.com" className="Login-LM-section">
          <h2>Business for Teams</h2>
          <p>Login or create account as a team member or an admin to manage your team's learning process.</p>
        </Link>
        
        <Link to="https://pay-per-course.lecturemeet.com" className="Login-LM-section">
          <h2>Pay per Course</h2>
          <p>Login or create account to continue your learning.</p>
        </Link>

        <Link to="https://pre-degree.lecturemeet.com" className="Login-LM-section">
          <h2>Pre-degree</h2>
          <p>Access diploma/Pre-degree programs.</p>
        </Link>
        
        <Link to="/CoomingSoon" className="Login-LM-section">
          <h2>Professional</h2>
          <p>Login or create account to access professional development courses in compliance, legal and corporate.</p>
        </Link>
      </div>
    </div>
  );
};

export default LoginLM;
