import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import './assets/css/header.css';
import './assets/css/footer.css';
import './assets/css/body.css';
import './assets/css/Chatbox.css';
import './assets/css/general.css';
import './assets/css/Main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import NavHeader from './components/NavHeader';
import ChatBox from './components/ChatBox';
import Routes from './Route';



function App() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Educational Organization",
    "name": "Lecturemeet",
    "url": "https://lecturemeet.com",
    "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
    "description": "Lecturemeet is an educational technology platform that facilitates online lectures and interactive learning experiences.",
    "sameAs": [
      "https://fb.me/Lecturemeet",
      "https://x.com/LectureMeet",
      "https://www.linkedin.com/company/lecturemeet",
      "https://www.instagram.com/lecturemeet"
    ],
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "FCT, Abuja, Nigeria",
      "addressLocality": "FCT",
      "addressRegion": "State",
      "postalCode": "560",
      "addressCountry": "Nigeria"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+234-276-90968",
      "contactType": "customer support",
      "email": "support@lecturemeet.com"
    },
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD",
      "availability": "https://schema.org/InStock"
    },
    "potentialAction": [
      {
        "@type": "FollowAction",
        "target": [
          {
            "@type": "EntryPoint",
            "urlTemplate": "https://fb.me/Lecturemeet",
            "actionPlatform": "https://schema.org/FacebookPlatform"
          },
          {
            "@type": "EntryPoint",
            "urlTemplate": "https://x.com/LectureMeet",
            "actionPlatform": "https://schema.org/TwitterPlatform"
          },
          {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.linkedin.com/company/lecturemeet",
            "actionPlatform": "https://schema.org/LinkedInPlatform"
          },
          {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.instagram.com/lecturemeet",
            "actionPlatform": "https://schema.org/InstagramPlatform"
          }
        ],
        "name": "Follow us on social media"
      }
    ]
  };

  return (
    <Router>
      <div className="App">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schema)}
          </script>
        </Helmet>
        <NavHeader />
        <Routes />
        <ChatBox />
        <Footer />
      </div>
    </Router>
  );
}

export default App;