import React from 'react';
import { Helmet } from 'react-helmet';
import GenerlaAsked from '../usercomponents/GenerlaAsked';


const GenralFaqs = () => {
  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Know about us | FAQ's | Lecturemeet </title>
        <meta name="description" content="Explore frequently asked questions (FAQs) about Lecturemeet. Find answers to common queries about our programs, enrollment process, payment options, and more." />
<meta name="keywords" content="Lecturemeet, FAQs, frequently asked questions, programs, enrollment, payment options" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Support/faqs",
        "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
      "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>

<meta property="og:title" content="FAQs | Lecturemeet" />
<meta property="og:description" content="Explore frequently asked questions (FAQs) about Lecturemeet. Find answers to common queries about our programs, enrollment process, payment options, and more." />
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
<meta property="og:url" content="https://lecturemeet.com/Support/faqs" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />
      </Helmet>

      <section class="faq-bg-section">
  <div class="About-container">
    <div class="about-content">
      <h2>Get to know about us</h2>
      <p>Knowldge is power. Find our resource center helpful to answer most of your questions</p>
    </div>
  </div>
</section>




<section>
    <GenerlaAsked />
</section>

    </div>
  );
};

export default GenralFaqs;
