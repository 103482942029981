import React, { useState, useEffect, useCallback } from 'react';
import { detectCurrency, convertAmountToCurrency, determineCourseCategory, getUserLocation, getCurrencySymbol } from '../usercomponents/currencyDetector';

const PayPerCourse = () => {
  const [currency, setCurrency] = useState('USD');
  const [amounts, setAmounts] = useState({});

  const calculateAmount = useCallback((courseCategory, currencyCode) => {
    let baseAmount = 0;
    switch (courseCategory) {
      case 'Business':
        baseAmount = 55;
        break;
      case 'LawLegalCompliance':
        baseAmount = 28;
        break;
      case 'Tech':
        baseAmount = 29;
        break;
      default:
        baseAmount = 55;
    }
    return convertAmountToCurrency(baseAmount, currencyCode);
  }, []);

  const formatAmount = (amount, currencyCode) => {
    const currencySymbol = getCurrencySymbol(currencyCode);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount).replace(/[A-Z]{3}/, currencySymbol); // Replace ISO currency code with the symbol
  };

  useEffect(() => {
    const setUserCurrency = async () => {
      const { country } = await getUserLocation();
      const detectedCurrency = detectCurrency(country);
      setCurrency(detectedCurrency);
    };
    setUserCurrency();
  }, []);

  useEffect(() => {
    const courseCategories = [
      'Business Development and Risk Compliance Officer',
      'Corporate Law Governance, and Regulatory Policies (CLG)',
      'Mergers and Acquisition Manager',
      'Human Resource Management and Compliance',
      'Anti Money Laundering (AML/CFT) Compliance Officer',
      'Banking and Financial Services Law',
      'Taxation Policy & Regulatory Compliance',
      'Data Protection Regulation: GDPR, & Regional Compliance Officer',
      'Intellectual Property Law',
      'Insurance Law & Policy (Regulatory framework - Nigeria)',
      'Product Management Fundamentals',
      'Product Marketing Guide',
      'Compliance in Payment Card Industry Data Security Standard (PCI DSS)',
    ];

    const amounts = {};
    courseCategories.forEach(course => {
      const category = determineCourseCategory(course);
      const amount = calculateAmount(category, currency);
      amounts[course] = formatAmount(amount, currency);
    });
    setAmounts(amounts);
  }, [calculateAmount, currency]);

  return (
    <div className='header-pay-percourse'>
      <header>
        <h1>Our Pay Per Course</h1>
        <p>Explore the wide range of our pay per course options offered <br />
          to enhance your knowledge and skills in various fields.</p>
      </header>
      <section className="pay-per-course-prog">
        <div className="pay-per-course-column">
          <h2>Business & Management</h2>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Business Development and Risk Compliance Officer
              <span> - {amounts['Business Development and Risk Compliance Officer']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Corporate Law Governance, and Regulatory Policies (CLG)
              <span> - {amounts['Corporate Law Governance, and Regulatory Policies (CLG)']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Mergers and Acquisition Manager
              <span> - {amounts['Mergers and Acquisition Manager']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Human Resource Management and Compliance
              <span> - {amounts['Human Resource Management and Compliance']}</span>
            </h3>
          </div>
        </div>
        <div className="pay-per-course-column">
          <h2>Compliance & Law</h2>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Anti Money Laundering (AML/CFT) Compliance Officer
              <span> - {amounts['Anti Money Laundering (AML/CFT) Compliance Officer']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Banking and Financial Services Law
              <span> - {amounts['Banking and Financial Services Law']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Taxation Policy & Regulatory Compliance
              <span> - {amounts['Taxation Policy & Regulatory Compliance']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Data Protection Regulation: GDPR, & Regional Compliance Officer
              <span> - {amounts['Data Protection Regulation: GDPR, & Regional Compliance Officer']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Intellectual Property Law
              <span> - {amounts['Intellectual Property Law']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Insurance Law & Policy (Regulatory framework - Nigeria)
              <span> - {amounts['Insurance Law & Policy (Regulatory framework - Nigeria)']}</span>
            </h3>
          </div>
        </div>
        <div className="pay-per-course-column">
          <h2>Technology & Innovation</h2>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Product Management Fundamentals
              <span> - {amounts['Product Management Fundamentals']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Product Marketing Guide
              <span> - {amounts['Product Marketing Guide']}</span>
            </h3>
          </div>
          <div className="pay-per-course-course">
            <h3>
              <i className="fa-solid fa-arrows-turn-right"></i> Compliance in Payment Card Industry Data Security Standard (PCI DSS)
              <span> - {amounts['Compliance in Payment Card Industry Data Security Standard (PCI DSS)']}</span>
            </h3>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PayPerCourse;
