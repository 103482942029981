import React from 'react';
import '../assets/css/Policy.css';
import { Helmet } from 'react-helmet';


const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-container">

<Helmet>
        <title>Privacy Policy | Lecturemeet </title>
        <meta name="description" content="Read Lecturemeet's Privacy Policy to understand how we collect, use, and protect your personal information when you use our website or services." />
<meta name="keywords" content="Lecturemeet, Privacy Policy, data protection, personal information, website, services" />
<meta name="author" content="Lecturemeet" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/terms/privacy",
        "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24"
        }
    `}
</script>

<meta property="og:title" content="Privacy Policy | Lecturemeet" />
<meta property="og:description" content="Read Lecturemeet's Privacy Policy to understand how we collect, use, and protect your personal information when you use our website or services." />
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
<meta property="og:url" content="https://lecturemeet.com/terms/privacy" />
<meta name="twitter:card" content="summary_large_image" />

      </Helmet>


      <>
      <h1>Privacy Policy</h1>

      <section className="privacy-section">
        <h2>Introduction</h2>
        <p>
          Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, use our services, or engage with us in other ways.
        </p>
      </section>

      <section className="privacy-section">
        <h2>Information We Collect</h2>
        <h3>Personal Information</h3>
        <p>
          We may collect personal information that you provide to us, such as your name, email address, phone number, payment information, and other identifying details.
        </p>

        <h3>Non-Personal Information</h3>
        <p>
          We may collect non-personal information about you whenever you interact with our site. This may include the browser name, the type of computer, and technical information about your means of connection to our site.
        </p>
      </section>

      <section className="privacy-section">
        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect in various ways, including to:
        </p>
        <ul>
          <li>Provide, operate, and maintain our services</li>
          <li>Improve, personalize, and expand our services</li>
          <li>Understand and analyze how you use our services</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
          <li>Process your transactions and manage your orders</li>
        </ul>
      </section>

      <section className="privacy-section">
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this Privacy Policy. This does not include trusted third parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
        </p>
      </section>

      <section className="privacy-section">
        <h2>Data Security</h2>
        <p>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </p>
      </section>

      <section className="privacy-section">
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>

      <section className="privacy-section">
      <h3>Changes to Terms</h3>
        <p>
          Lecturemeet reserves the right to modify these terms at any time. We will notify you of any changes by updating the terms on our website. Continued use of our services after changes constitutes acceptance of the new terms.
        </p>

        <h2>Contact Information</h2>
        <p>
          If you have any questions about these terms and policies, please contact us at:
        </p>
        <p>
          Email: support@lecturemeet.com<br />
          Abuja, FCT, Nigeria
        </p>
      </section>


      </>
    </div>
  );
};

export default PrivacyPolicyPage;
