import React from 'react';
import '../../assets/css/Pre-degree.css';

const LearningModel = () => {
    return (
        <section className="learning-model" id="learning-model">
            <div className="container">
                <h2>Learning Model</h2>
                <div className="accordion" id="learningModelAccordion">
                    {/* First Session */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="pre-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Session Overview
                                <span className="material-icons float-end">+</span>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#learningModelAccordion">
                            <div className="pre-accordion-body">
                                <table className="predegree-table">
                                    <thead>
                                        <tr>
                                            <th>Course/Topic</th>
                                            <th>Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Introduction to History</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Globalization and International Relations</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>World History Overview</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>International Relations Theories</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Research Methods in History</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Political History of Africa</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Contemporary Global Issues</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Diplomatic History</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>History of International Organizations</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Critical Thinking in History</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Ethics in International Relations</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>International Law</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>History of Human Rights</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Long Essay</td>
                                            <td>5</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="text-end font-weight-bold">Total Units: 70</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="predegree-table">
                                    <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Assessment</td>
                                            <td>Evaluation of understanding through MCQ and written assessments</td>
                                        </tr>
                                        <tr>
                                            <td>Revision</td>
                                            <td>Review of course materials in the sessions</td>
                                        </tr>
                                        <tr>
                                            <td>Exams</td>
                                            <td>Final examination</td>
                                        </tr>
                                        <tr>
                                            <td>Result and Certification</td>
                                            <td>Graduation and certificate collection</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="text-end font-weight-bold">Pass Grade: 75% Minimum + Attendance</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LearningModel;
