import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import '../assets/css/Support.css';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const AskMe = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    surname: '',
    email: '',
    phone: '',
    course: '',
    terms: false,
    marketingConsent: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // Track modal visibility

  // Handle form changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setModalMessage("Submitting...");
    setShowModal(true);

    try {
      await addDoc(collection(db, 'RequestForm'), {
        ...formData,
        submittedAt: new Date(),
      });
      setModalMessage("Thank you! Your request has been submitted successfully.");
    } catch (error) {
      setModalMessage("Submission failed. Please try again.");
      console.error("Error submitting form: ", error);
    } finally {
      setIsSubmitting(false);
      
      // Automatically hide the modal after 3 seconds
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };

  // Close the modal manually if needed
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container-mx-auto-p-4">
      
      <Helmet>
        <title>Reach us | Lecturemeet </title>
        <meta name="description" content="Reach out to Lecturemeet for comprehensive support. Fill out the feedback form, and our team will be in touch." />
        <meta name="keywords" content="Lecturemeet support, feedback form, student support, assistance" />
        <meta name="author" content="Lecturemeet Team" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

<script type="application/ld+json">
    {`
        {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Lecturemeet",
        "url": "https://lecturemeet.com/Support/ask-me",
        "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
      "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>

<meta property="og:title" content="FAQs | Lecturemeet" />
<meta property="og:description" content="Reach out to Lecturemeet for comprehensive support. Fill out the feedback form, and our team will be in touch." />
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
<meta property="og:url" content="https://lecturemeet.com/Support/ask-me" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@LectureMeet" />
        <meta name="twitter:creator" content="@LectureMeet" />
      </Helmet>


      <section className="faq-bg-section">
        <div className="About-container">
          <div className="about-content">
            <h2>Reach out to us.</h2>
            <p>Fill our feedback form and we will be in touch</p>
          </div>
        </div>
      </section>

      <section className="admission-details-container">
        <div className="admission-details-content">
          <div className="admission-info">
            <h2>Comprehensive Support System</h2>
            <p>At Lecturemeet, we prioritize student support and engagement to ensure that every learner has the resources and assistance they need to succeed.</p>
            <ul>
              <li><strong>Dedicated support team available to assist students with any inquiries or challenges they may face</strong></li>
              <li><strong>Lecturemeet keeps students informed through regular updates</strong></li>
              <li><strong>Provide feedback on your experiences</strong></li>
            </ul>
          </div>
          <div className="admission-form">
            <form id="studentAdmissionForm" onSubmit={handleSubmit}>
              <div className="name-fields">
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="middleName">Middle Name</label>
                  <input
                    type="text"
                    id="middleName"
                    name="middleName"
                    value={formData.middleName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="surname">Surname</label>
                  <input
                    type="text"
                    id="surname"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />

              <label htmlFor="course">What do you want to learn?</label>
              <select
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select a course</option>
                <option value="Diploma program">Diploma Program</option>
                <option value="Business Development and Risk Compliance Officer ">Business Development and Risk Compliance Officer</option>
                <option value="Corporate Law Governance, and Regulatory Policies (CLG)">Corporate Law Governance, and Regulatory Policies (CLG)</option>
                <option value="Human Resource Management and Compliance">Human Resource Management and Compliance</option>
                <option value="Anti Money Laundering (AML/CFT) Compliance Officer">Anti Money Laundering (AML/CFT) Compliance Officer</option>
                <option value="Taxation Policy & Regulatory Compliance">Taxation Policy & Regulatory Compliance</option>
                <option value="Data Protection Regulation: GDPR, & Regional Compliance Officer">Data Protection Regulation: GDPR, & Regional Compliance Officer</option>
                <option value="Intellectual Property Law">Intellectual Property Law</option>
                <option value="Product Management Fundamentals">Product Management Fundamentals</option>
                <option value="Product Marketing Guide">Product Marketing Guide</option>
                <option value="Compliance in Payment Card Industry Data Security Standard (PCI DSS)">Compliance in Payment Card Industry Data Security Standard (PCI DSS)</option>
              </select>

              
              <div className="checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    name="terms"
                    checked={formData.terms}
                    onChange={handleChange}
                    required
                  />
                  I consent to the Terms and Policies
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="marketingConsent"
                    checked={formData.marketingConsent}
                    onChange={handleChange}
                  />
                  I consent to receive marketing communications
                </label>
              </div>

              <button type="submit" className="submit-button" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
              </button>
            </form>
          </div>
        </div>

        {/* Modal for submission status */}
        {showModal && (
          <div id="modal" className="modal active">
            <div className="modal-content">
              {isSubmitting && <div id="spinner" className="spinner"></div>}
              <p id="modalMessage">{modalMessage}</p>
              <button onClick={closeModal} className="close-modal-button">Close</button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default AskMe;
