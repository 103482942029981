import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Lecturemeet.png';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const closeMenu = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setShowDropdown1(false);
    }
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setShowDropdown2(false);
    }
    if (!event.target.closest('.menu-toggle') && !event.target.closest('.nav-menu')) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo-container">
          <Link to="https://lecturemeet.com">
            <img src={logo} alt="Lecturemeet Logo" className="logo-image" />
          </Link>
          
        </div>
        <div className={`menu-toggle ${showMenu ? 'active' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`nav-menu ${showMenu ? 'active' : ''}`}>
          <li><Link to="/About" onClick={toggleMenu}>About</Link></li>
          <li className="dropdown">
            <Link to="#" aria-haspopup="true" onClick={toggleDropdown1}>
              Programmes <i className="fa fa-angle-down"></i>
            </Link>
            <ul ref={dropdownRef1} className={`dropdown-menu ${showDropdown1 ? 'active' : ''}`}>
              <li><Link to="/Pre-degree" onClick={toggleMenu}>Pre-Degree Programme</Link></li>
              <li><Link to="/Legal-and-compliance" onClick={toggleMenu}>Legal & Compliance</Link></li>
              <li><Link to="/Corporate-and-banking-sector" onClick={toggleMenu}>Corporate & Finance</Link></li>
              <li><Link to="/Human-resource-and-product-development-studies" onClick={toggleMenu}>Human Resource</Link></li>
              <li><Link to="/Product-Management" onClick={toggleMenu}>Product Management</Link></li>
              <li><Link to="/Scholarship" onClick={toggleMenu}>Scholarship</Link></li>
            </ul>
          </li>
          <li className="dropdown hidenav">
            <Link to="#" aria-haspopup="true" onClick={toggleDropdown2}>
              Program learning Models <i className="fa fa-angle-down"></i>
            </Link>
            <ul ref={dropdownRef2} className={`dropdown-menu ${showDropdown2 ? 'active' : ''}`}>
              <li><Link to="/Programme" onClick={toggleMenu}>Compliance</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>AML/CFT</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Due process & corporate governance for private sectors</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Due process & corporate governance for public sectors</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Mergers & Acquisitions</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Taxation</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Risk Management</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Human Resource Management</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Corporate Governance</Link></li>
              <li><Link to="/Programme" onClick={toggleMenu}>Product management</Link></li>
            </ul>
          </li>
          <li><Link to="/organisations" onClick={toggleMenu}>For Organisations</Link></li>
          <li><Link to="/Pay-per-course" onClick={toggleMenu}>Pay Per Course</Link></li>
          <li><Link to="/Login" className="login-button" onClick={toggleMenu} style={{color: "#ffffff"}}>Account</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
