import React from 'react';
import { Helmet } from 'react-helmet';
import degreecourse from '../assets/images/degreecourse.jpg';
import campushall from '../assets/images/campushall.avif';
import { Link } from 'react-router-dom';
import PredegreeTestimonial from '../usercomponents/PredegreeTestimonial';
import Meta from '../components/Meta';



const DegreeProgram = () => {

  return (
    <div className="container-mx-auto-p-4">
      <Helmet>
        <title>Pre-Degree Programme | Lecturemeet </title>
        <Meta name="description" content="Build a strong foundation for your academic and professional journey. Enroll in our Pre-Degree courses in Law, Business Administration, and Mass Communications and Media studies, Human Resource Management." />
<Meta name="keywords" content="Lecturemeet, Pre-Degree Programme, foundation courses, Law, Business Administration, Mass Communications, academic preparation, professional development, education" />
<Meta name="author" content="Lecturemeet" />
<Meta name="viewport" content="width=device-width, initial-scale=1.0" />
<Meta charset="UTF-8" />
<Meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://lecturemeet.com/pre-degree",
              "logo": "https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24",
      "description": "Build a strong foundation for your academic and professional journey. Enroll in our Pre-Degree courses in Law, Business Administration, and Mass Communications and Media studies, Human Resource Management.",
              "sameAs": [
        "https://www.facebook.com/Lecturemeet",
        "https://x.com/LectureMeet",
        "https://ng.linkedin.com/company/lecturemeet"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+234-812-769-0968",
        "contactType": "Customer Service"
      }
    }
  `}
</script>
        
        <Meta property="og:title" content="Pre-Degree Programme | Lecturemeet" />
        <Meta property="og:description" content="Build a strong foundation for your academic and professional journey. Enroll in our Pre-Degree courses in Law, Business Administration, and Mass Communications." />
        <Meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/flexicheap.appspot.com/o/Lecturemeet%2FLecturemeet.png?alt=media&token=29c2960d-6619-48c5-b3a2-95ad8a7c5a24" />
        <Meta property="og:url" content="https://lecturemeet.com/pre-degree" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="twitter:site" content="@LectureMeet" />
        <Meta name="twitter:creator" content="@LectureMeet" />
        

        <link rel="canonical" href="https://lecturemeet.com/pre-degree" />
        <meta name="robots" content="index, follow"></meta>
      </Helmet>

      <section class="about-background-section">
  <div class="About-container">
    <div class="about-content">
      <h2>Stay ahead of the learning curve</h2>
      <p> Our innovative approach to education ensures that you are prepared for the challenges of university and beyond</p>
    </div>
  </div>
  
</section>



<section className="prog-190">
    <div className="container">
      <div className="row">        
      <div className="col-md-6 pmsect-09">
          <img src={degreecourse} alt="Our Programmes" className="img-fluid" />
            </div>
        <div className="col-md-6 pmsect-09">
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: 'left', color: 'blue' }}>Beyond the walls of classrooms</h1>
          <p>The Pre-degree programme at Lecturemeet offers foundational 
            courses in subjects like Law, Business Administration, 
            and Mass Communications and media studies, Human Resource Management. </p>
            <Link to="https://airtable.com/app9byZYdCUy39ZEQ/shrctSRqEvPKHEtup" target='_blank' className="btn btn-primary">Register for Pre-degree Programme</Link>

        </div>

      </div>
    </div>
  </section>


  <section className="prog-ram-sect">
    <div className="container">
      <div className="row">
        <div className='prede'>
        <h2>Why Choose Our Pre-degree Courses</h2>
        <p>Our Pre-degree courses at Lecturemeet offer a unique learning <br/>
         experience designed to prepare students for success <br/>
          in university-level studies. Here's why you should choose our <br/>
          Pre-degree programme</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>University curriculum based</h3>
          <p>Our courses are developed based on university-level curriculum, giving students a head start in their academic journey.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Foundation Crash Program</h3>
          <p>We offer a foundation crash program that provides a solid grounding in key subjects like Law, Business Administration, and Mass Communications, ensuring students are well-prepared for university-level studies.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Flexible Learning</h3>
          <p>We offer flexible learning allowing students to study at their own pace and convenience, making it easier to balance their studies with other commitments and at the same time, our programmes are cost-effective compared to traditional university programmes.</p>
        </div>
        <div className="col-md-3 hkpime">
          <h3>Certification</h3>
          <p>Upon completion of our Pre-degree courses, students receive a certificate enhancing their academic and professional prospects.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="prog-ram-sect">
  <div className="container">
    <div className="row">
      <div className="col-md-6 pmsect-09">
        <h2>Closing the gap in development</h2>
        <p>The programme aims to bridge the gap between secondary 
          education and higher education, equipping students with 
          the necessary skills and knowledge to succeed in their 
          academic pursuits.</p>


        <h3>Stay ahead</h3>
        <p>Designed to provide students with a comprehensive understanding 
          of key concepts and principles in these fields, preparing them 
          for further studies at the university level.</p>
      </div>
      <div className="col-md-6 pmsect-09">
        <img src={campushall} alt="Our Programmes" className="img-fluid" />
      </div>
    </div>
    <br />
    <br />
    <div className="row grid-courses-pre">
      <div className="col-md-4 pre-degree-courses">
        <h3>Mass communications and media studies</h3>
        <p>This program introduces students who are just rounding up with college to the dynamic world of mass media, covering areas such as journalism, broadcasting, public relations, and advertising. </p>
        <Link to="/pre-degree/Mass-Communication" style={{ color: 'black', textDecoration: 'none' }}>Know More <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
      </div>
       <div className="col-md-4 pre-degree-courses">
        <h3>Foundation in Law</h3>
        <p>The Foundation in Law program is ideal for students considering a career in law. This course provides a strong foundation background into law for further studies in law covering various aspects of law in year 1- 2 including legal method, contract law, criminal law, family law.</p>
        <Link to="/pre-degree/Law" style={{ color: 'black', textDecoration: 'none' }}>Know More <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
      </div>
      <div className="col-md-4 pre-degree-courses">
        <h3>History and International Relations</h3>
        <p>This program aims to prepare students for further academic pursuits in history, political science, and international relations, as well as careers in diplomacy, government, non-governmental organizations, and international businesses.</p>
        <Link to="/pre-degree/HIR" style={{ color: 'black', textDecoration: 'none' }}>Know More <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
      </div>
      <div className="col-md-4 pre-degree-courses">
        <h3>Business Administration</h3>
        <p>The Business Administration program is designed to equip students with essential knowledge and skills in business management. Students learn about various aspects of business, including management principles, marketing strategies, financial management.</p>
        <Link to="/pre-degree/Business-Administration" style={{ color: 'black', textDecoration: 'none' }}>Know More <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8m5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707z"/>
  </svg>
</Link>
      </div>
    </div>
  </div>
</section>


<PredegreeTestimonial />


    </div>
  );
};

export default DegreeProgram;
